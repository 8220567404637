
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 0;
  z-index: 5;

  @include mobile() {
    padding: 8px 0;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #222222;
    transition: all 100ms ease;
    transition-delay: 200ms;

    &.white {
      color: #fff;
      transition-delay: 100ms;
    }

    span {
      display: block;
      margin-left: 10px;
      font-family: 'Redcollar', sans-serif;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.02em;

      @include mobile() {
        display: none;
      }
    }
  }

  button {
    display: block;
    background: transparent;
    border: 1px solid #222222;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 100px;
    font-family: 'Redcollar', sans-serif;
    font-size: 16px;
    line-height: 90%;
    color: #222222;
    transition: all 100ms ease;
    transition-delay: 200ms;

    &.white {
      color: #fff;
    }

    &.white {
      border-color: #fff;
      color: #fff;
      transition-delay: 100ms;
    }

  }
}
