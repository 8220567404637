
.particles {
  --name-block-height: 114px;
  --appear-time: 400ms;
  -webkit-tap-highlight-color: transparent;


  @include mobile() {
    --name-block-height: 64px;
  }

  &__scene {
    background: #222222;
    transition: var(--appear-time) ease all;
    height: calc(var(--total-height) - var(--name-block-height));
    position: relative;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    touch-action: none;

    .text {
      position: relative;
      display: flex;
      height: calc(var(--total-height) - var(--name-block-height));
      width: 100%;
      margin: 0 auto;
      z-index: 1;
      align-items: center;
      justify-content: flex-start;
      font-family: 'TT Commons', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 90%;
      color: #fff;
      pointer-events: none;
      padding: 0 40px 0 180px;
      max-width: 1440px;
      box-sizing: border-box;

      @include mobile() {
        display: none;
      }

      span {
        display: block;
        white-space: pre-wrap;
        max-width: 260px;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-20px);
        transition: 400ms  cubic-bezier(0.11, 0, 0.5, 0) all;
      }

      &.active span {
        transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
        visibility: visible;
        opacity: 1;
        transform: none;
      }
    }

    .canvas-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(var(--total-height) - var(--name-block-height));
      transform: translateX(0%);
      overflow: hidden;

      @include mobile() {
        transform: none;
        touch-action: pan-x;
      }

      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__name {
    background: #fff;
    color: #222222;
    height: var(--name-block-height);
    transition: var(--appear-time) ease all;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 40px;

      @include mobile() {
        padding: 16px;
        align-items: flex-start;
      }

      h1 {
        margin: 0;
        padding: 0;
        font-family: 'Redcollar', sans-serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 90%;

        @include mobile() {
          font-size: 18px;
          line-height: 90%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        font-family: 'TT Commons', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 90%;
        text-align: right;

        p, a {
          display: block;
          margin: 0;
          padding: 0;
          text-decoration: none;
        }

        @include mobile() {
          p {
            display: none;
          }

          a {
            margin-top: 2px;
          }
        }

        a {
          color: #f00;
        }
      }
    }

  }

  &.collapse &__scene {
    height: 0;
  }

  &.collapse &__name {
    padding-top: 160px;

    @include mobile() {
      padding-top: 80px;
    }
  }

}
