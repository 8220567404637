@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-Regular.eot');
  src: local('TT Commons Regular'), local('TTCommons-Regular'),
  url('../fonts/TTCommons-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-Regular.woff2') format('woff2'),
  url('../fonts/TTCommons-Regular.woff') format('woff'),
  url('../fonts/TTCommons-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-Thin.eot');
  src: local('TT Commons Thin'), local('TTCommons-Thin'),
  url('../fonts/TTCommons-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-Thin.woff2') format('woff2'),
  url('../fonts/TTCommons-Thin.woff') format('woff'),
  url('../fonts/TTCommons-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-Italic.eot');
  src: local('TT Commons Italic'), local('TTCommons-Italic'),
  url('../fonts/TTCommons-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-Italic.woff2') format('woff2'),
  url('../fonts/TTCommons-Italic.woff') format('woff'),
  url('../fonts/TTCommons-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-ThinItalic.eot');
  src: local('TT Commons Thin Italic'), local('TTCommons-ThinItalic'),
  url('../fonts/TTCommons-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-ThinItalic.woff2') format('woff2'),
  url('../fonts/TTCommons-ThinItalic.woff') format('woff'),
  url('../fonts/TTCommons-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-Bold.eot');
  src: local('TT Commons Bold'), local('TTCommons-Bold'),
  url('../fonts/TTCommons-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-Bold.woff2') format('woff2'),
  url('../fonts/TTCommons-Bold.woff') format('woff'),
  url('../fonts/TTCommons-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-ExtraBold.eot');
  src: local('TT Commons ExtraBold'), local('TTCommons-ExtraBold'),
  url('../fonts/TTCommons-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-ExtraBold.woff2') format('woff2'),
  url('../fonts/TTCommons-ExtraBold.woff') format('woff'),
  url('../fonts/TTCommons-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-Light.eot');
  src: local('TT Commons Light'), local('TTCommons-Light'),
  url('../fonts/TTCommons-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-Light.woff2') format('woff2'),
  url('../fonts/TTCommons-Light.woff') format('woff'),
  url('../fonts/TTCommons-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-LightItalic.eot');
  src: local('TT Commons Light Italic'), local('TTCommons-LightItalic'),
  url('../fonts/TTCommons-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-LightItalic.woff2') format('woff2'),
  url('../fonts/TTCommons-LightItalic.woff') format('woff'),
  url('../fonts/TTCommons-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-BoldItalic.eot');
  src: local('TT Commons Bold Italic'), local('TTCommons-BoldItalic'),
  url('../fonts/TTCommons-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-BoldItalic.woff2') format('woff2'),
  url('../fonts/TTCommons-BoldItalic.woff') format('woff'),
  url('../fonts/TTCommons-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-BlackItalic.eot');
  src: local('TT Commons Black Italic'), local('TTCommons-BlackItalic'),
  url('../fonts/TTCommons-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-BlackItalic.woff2') format('woff2'),
  url('../fonts/TTCommons-BlackItalic.woff') format('woff'),
  url('../fonts/TTCommons-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-Medium.eot');
  src: local('TT Commons Medium'), local('TTCommons-Medium'),
  url('../fonts/TTCommons-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-Medium.woff2') format('woff2'),
  url('../fonts/TTCommons-Medium.woff') format('woff'),
  url('../fonts/TTCommons-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-Black.eot');
  src: local('TT Commons Black'), local('TTCommons-Black'),
  url('../fonts/TTCommons-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-Black.woff2') format('woff2'),
  url('../fonts/TTCommons-Black.woff') format('woff'),
  url('../fonts/TTCommons-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-ExtraLight.eot');
  src: local('TT Commons ExtraLight'), local('TTCommons-ExtraLight'),
  url('../fonts/TTCommons-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-ExtraLight.woff2') format('woff2'),
  url('../fonts/TTCommons-ExtraLight.woff') format('woff'),
  url('../fonts/TTCommons-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/TTCommons-DemiBold.eot');
  src: local('TT Commons DemiBold'), local('TTCommons-DemiBold'),
  url('../fonts/TTCommons-DemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTCommons-DemiBold.woff2') format('woff2'),
  url('../fonts/TTCommons-DemiBold.woff') format('woff'),
  url('../fonts/TTCommons-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Redcollar';
  src: url('../fonts/Redcollar.eot');
  src: local('Redcollar'),
  url('../fonts/Redcollar.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Redcollar.woff2') format('woff2'),
  url('../fonts/Redcollar.woff') format('woff'),
  url('../fonts/Redcollar.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

