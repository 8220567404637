
@import "fonts";

:root {
  --total-height: 100vh;
}

@mixin mobile() {
  @media (max-width: 800px) {
    @content
  }
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #fff;
  font-family: 'TT Commons', sans-serif;
}

.wrapper {
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;

  @include mobile() {
    padding: 0 16px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.flow-wrap {
  height: 100%;
}

@import "header";
@import "particles";
@import "content";
