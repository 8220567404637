
.content {
  overflow: hidden;
  transition: 800ms ease all;
  height: 0;

  &__info {
    display: none;
    font-family: 'TT Commons', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 90%;
    text-align: right;
    padding: 100px 0 16px;

    @include mobile() {
      display: block;
    }
  }

  &__wrap .wrapper .line {
    border-top: 1px solid #222222;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 64px;
    padding: 50px 180px 50px 140px;
    max-width: 840px;

    @include mobile() {
      max-width: unset;
      padding: 64px 0;
    }

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: #222222;
      font-family: 'TT Commons', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 90%;
      text-transform: lowercase;

      &.break {
        color: #f00;
      }

      &__text {
        flex: 1 1;
        max-width: 260px;
      }

      &__time {
        flex: 0 0;
      }
    }

  }

  &__footer {
    padding: 200px 40px 27px;
    font-family: 'TT Commons', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 90%;

    @include mobile() {
      padding: 120px 16px 16px;
    }
  }

}
